<template>
  <!--begin::Sign-in Method-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
    >
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">Basic Information</h3>
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_signin_method" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9">
        <!--begin::Email Address-->
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div id="kt_signin_name" :class="{ 'd-none': nameFormDisplay }">
            <div class="fs-4 fw-boldest mb-1">Name</div>
            <div class="fs-6 fw-bold text-gray-600">{{ user.name }} {{ user.surname }}</div>
          </div>

          <div
            id="kt_signin_name_edit"
            :class="{ 'd-none': !nameFormDisplay }"
            class="flex-row-fluid"
          >
            <!--begin::Form-->
            <Form
              id="kt_signin_change_name"
              class="form"
              novalidate="novalidate"
              @submit="updateName"
              :validation-schema="changeNameSchema"
            >
              <div class="row mb-6">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="fv-row mb-0">
                    <label for="name" class="form-label fs-6 fw-bolder mb-3">First Name</label>
                    <Field
                      type="text"
                      class="form-control form-control-lg form-control-solid fw-bold fs-6"
                      id="name"
                      placeholder="First Name"
                      name="name"
                      :value="user.name"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="fv-row mb-0">
                    <label for="surname" class="form-label fs-6 fw-bolder mb-3">Last Name</label>
                    <Field
                      type="text"
                      class="form-control form-control-lg form-control-solid fw-bold fs-6"
                      id="surname"
                      placeholder="Last Name"
                      name="surname"
                      :value="user.surname"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="surname" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_update_name_submit"
                  type="submit"
                  ref="updateNameButton"
                  class="btn btn-primary me-2 px-6"
                >
                  <span class="indicator-label">Update Name</span>
                  <span class="indicator-progress">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button
                  id="kt_update_name_cancel"
                  type="button"
                  class="btn btn-color-gray-400 btn-active-light-primary px-6"
                  @click="nameFormDisplay = !nameFormDisplay"
                >Cancel</button>
              </div>
            </Form>
            <!--end::Form-->
          </div>
          <div id="kt_signin_email_button" :class="{ 'd-none': nameFormDisplay }" class="ms-auto">
            <button
              class="btn btn-light fw-boldest px-6"
              @click="nameFormDisplay = !nameFormDisplay"
            >Change Name</button>
          </div>
        </div>
        <!--end::Email Address-->

        <!--begin::Password-->
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div id="kt_signin_password" :class="{ 'd-none': passwordFormDisplay }">
            <div class="fs-4 fw-boldest mb-1">Password</div>
            <div class="fs-6 fw-bold text-gray-600">************</div>
          </div>
          <div
            id="kt_signin_password_edit"
            class="flex-row-fluid"
            :class="{ 'd-none': !passwordFormDisplay }"
          >
            <div
              class="fs-6 fw-bold text-gray-600 mb-4"
            >Password must be at least 6 character and contain symbols</div>

            <!--begin::Form-->
            <Form
              id="kt_signin_change_password"
              class="form"
              novalidate="novalidate"
              @submit="updatePassword"
              :validation-schema="changePasswordSchema"
            >
              <div class="row mb-6">
                <div class="col-lg-4">
                  <input
                    type="email"
                    :value="user.email"
                    autocomplete="username"
                    v-show="hiddenEmailDisplay"
                  />
                  <div class="fv-row mb-0">
                    <label
                      for="currentpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                    >Current Password</label>
                    <Field
                      type="password"
                      class="form-control form-control-lg form-control-solid fw-bold fs-6"
                      name="currentpassword"
                      id="currentpassword"
                      autocomplete="none"
                      @input="currentPasswordError = ''"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <span
                          role="alert"
                          v-if="currentPasswordError !== ''"
                        >Current password is invalid</span>
                        <ErrorMessage name="currentpassword" v-else />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label for="newpassword" class="form-label fs-6 fw-bolder mb-3">New Password</label>
                    <Field
                      type="password"
                      class="form-control form-control-lg form-control-solid fw-bold fs-6"
                      name="newpassword"
                      id="newpassword"
                      autocomplete="new-password"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="newpassword" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="confirmpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                    >Confirm New Password</label>
                    <Field
                      type="password"
                      class="form-control form-control-lg form-control-solid fw-bold fs-6"
                      name="confirmpassword"
                      id="confirmpassword"
                      autocomplete="new-password"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="confirmpassword" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_password_submit"
                  type="submit"
                  ref="updatePasswordButton"
                  class="btn btn-primary me-2 px-6"
                >
                  <span class="indicator-label">Update Password</span>
                  <span class="indicator-progress">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button
                  id="kt_password_cancel"
                  type="button"
                  @click="passwordFormDisplay = !passwordFormDisplay"
                  class="btn btn-color-gray-400 btn-active-light-primary px-6"
                >Cancel</button>
              </div>
            </Form>
            <!--end::Form-->
          </div>
          <div
            id="kt_signin_password_button"
            class="ms-auto"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <button
              @click="passwordFormDisplay = !passwordFormDisplay"
              class="btn btn-light fw-boldest"
            >Reset Password</button>
          </div>
        </div>
        <!--end::Password-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Sign-in Method-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, resolveComponent } from "vue";
import { Store, useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  Actions as UserActions,
  Mutations as UserMutations,
} from "@/store/enums/UserEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useDebounceFn } from '@vueuse/core'

interface ProfileDetails {
  avatar: string;
  name: string;
  surname: string;
  company: string;
  contactPhone: string;
  companySite: string;
  country: string;
  language: string;
  timezone: string;
  currency: string;
  communications: {
    email: boolean;
    phone: boolean;
  };
  allowMarketing: boolean;
}

export default defineComponent({
  name: "profile-settings",
  props: {
    user: Object,
    profile: Object,
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const store = useStore();

    const updateEmailButton = ref<HTMLElement | null>(null);
    const updateNameButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const hiddenEmailDisplay = ref(false);
    const nameFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);
    const currentPasswordError = ref('');

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required().label("First name"),
      lname: Yup.string().required().label("Last name"),
      company: Yup.string().required().label("Company"),
      phone: Yup.string().required().label("Contact phone"),
      website: Yup.string().label("Webside"),
      country: Yup.string().required().label("Country"),
      language: Yup.string().required().label("Language"),
      timezone: Yup.string().required().label("Timezone"),
      currency: Yup.string().required().label("Currency"),
    });

    const changeNameSchema = Yup.object().shape({
      name: Yup.string().required().label("First name"),
      surname: Yup.string().required().label("Last name")
    })

    const changeEmailSchema = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePasswordSchema = Yup.object().shape({
      // .test('validate-current-password', value => new Promise(resolve => debouncedFn(value, resolve)))
      currentpassword: Yup.string().required().label("Current password"),
      newpassword: Yup.string().min(6).required().label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const profileDetails = ref<ProfileDetails>({
      avatar: "media/avatars/150-2.jpg",
      name: props.user!.name,
      surname: props.user!.surname,
      company: "Keenthemes",
      contactPhone: "044 3276 454 935",
      companySite: "keenthemes.com",
      country: "MY",
      language: "msa",
      timezone: "Kuala Lumpur",
      currency: "USD",
      communications: {
        email: false,
        phone: false,
      },
      allowMarketing: false,
    });

    const updateName = (values) => {
      const name = props.user?.name;
      const surname = props.user?.surname;

      const currentName = {
        name: name,
        surname: surname
      }

      if (updateNameButton.value) {
        // Activate indicator
        updateNameButton.value.setAttribute("data-kt-indicator", "on");
        if (JSON.stringify(values) === JSON.stringify(currentName)) {
          // No change
          updateNameButton.value?.removeAttribute("data-kt-indicator");
          nameFormDisplay.value = false;
          return;
        }

        store.dispatch(UserActions.UPDATE_PROFILE, values).then(() => {
          updateNameButton.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: "Name has been successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            nameFormDisplay.value = false;
          });
        })

      }
    };

    const updateEmail = () => {
      console.log(updateEmailButton.value);

      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const updatePassword = (values, actions) => {
      console.log(actions)
      console.log(values)
      const payload = { ...values, email: props.user?.email }

      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        store.dispatch(UserActions.VERIFY_CURRENT_PASSWORD, { email: props.user?.email, password: values.currentpassword })
          .then(() => {
            store.dispatch(UserActions.CHANGE_CURRENT_PASSWORD, { password: values.confirmpassword })
              .then(() => {
                updatePasswordButton.value?.removeAttribute("data-kt-indicator");
                Swal.fire({
                  text: "Password is successfully changed!",
                  icon: "success",
                  confirmButtonText: "Ok",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-light-primary",
                  },
                }).then(() => {
                  passwordFormDisplay.value = false;
                  actions.resetForm();
                });
              })
          }).catch((error) => {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            // changePasswordSchema.fields.currentpassword.typeError(error.message)
            // console.log(changePasswordSchema)
            currentPasswordError.value = error.message
            console.log(error.message)
          })
      }
    };

    const removeImage = () => {
      profileDetails.value.avatar = "media/avatars/blank.png";
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    return {
      hiddenEmailDisplay,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      removeImage,
      profileDetailsValidator,
      changeEmailSchema,
      changePasswordSchema,
      changeNameSchema,
      updateNameButton,
      updateEmailButton,
      updatePasswordButton,
      updateName,
      updateEmail,
      updatePassword,
      nameFormDisplay,
      currentPasswordError
    };
  },
});
</script>
