
import { defineComponent, onMounted, ref, resolveComponent } from "vue";
import { Store, useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  Actions as UserActions,
  Mutations as UserMutations,
} from "@/store/enums/UserEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useDebounceFn } from '@vueuse/core'

interface ProfileDetails {
  avatar: string;
  name: string;
  surname: string;
  company: string;
  contactPhone: string;
  companySite: string;
  country: string;
  language: string;
  timezone: string;
  currency: string;
  communications: {
    email: boolean;
    phone: boolean;
  };
  allowMarketing: boolean;
}

export default defineComponent({
  name: "profile-settings",
  props: {
    user: Object,
    profile: Object,
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const store = useStore();

    const updateEmailButton = ref<HTMLElement | null>(null);
    const updateNameButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const hiddenEmailDisplay = ref(false);
    const nameFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);
    const currentPasswordError = ref('');

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required().label("First name"),
      lname: Yup.string().required().label("Last name"),
      company: Yup.string().required().label("Company"),
      phone: Yup.string().required().label("Contact phone"),
      website: Yup.string().label("Webside"),
      country: Yup.string().required().label("Country"),
      language: Yup.string().required().label("Language"),
      timezone: Yup.string().required().label("Timezone"),
      currency: Yup.string().required().label("Currency"),
    });

    const changeNameSchema = Yup.object().shape({
      name: Yup.string().required().label("First name"),
      surname: Yup.string().required().label("Last name")
    })

    const changeEmailSchema = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePasswordSchema = Yup.object().shape({
      // .test('validate-current-password', value => new Promise(resolve => debouncedFn(value, resolve)))
      currentpassword: Yup.string().required().label("Current password"),
      newpassword: Yup.string().min(6).required().label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const profileDetails = ref<ProfileDetails>({
      avatar: "media/avatars/150-2.jpg",
      name: props.user!.name,
      surname: props.user!.surname,
      company: "Keenthemes",
      contactPhone: "044 3276 454 935",
      companySite: "keenthemes.com",
      country: "MY",
      language: "msa",
      timezone: "Kuala Lumpur",
      currency: "USD",
      communications: {
        email: false,
        phone: false,
      },
      allowMarketing: false,
    });

    const updateName = (values) => {
      const name = props.user?.name;
      const surname = props.user?.surname;

      const currentName = {
        name: name,
        surname: surname
      }

      if (updateNameButton.value) {
        // Activate indicator
        updateNameButton.value.setAttribute("data-kt-indicator", "on");
        if (JSON.stringify(values) === JSON.stringify(currentName)) {
          // No change
          updateNameButton.value?.removeAttribute("data-kt-indicator");
          nameFormDisplay.value = false;
          return;
        }

        store.dispatch(UserActions.UPDATE_PROFILE, values).then(() => {
          updateNameButton.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: "Name has been successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            nameFormDisplay.value = false;
          });
        })

      }
    };

    const updateEmail = () => {
      console.log(updateEmailButton.value);

      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const updatePassword = (values, actions) => {
      console.log(actions)
      console.log(values)
      const payload = { ...values, email: props.user?.email }

      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        store.dispatch(UserActions.VERIFY_CURRENT_PASSWORD, { email: props.user?.email, password: values.currentpassword })
          .then(() => {
            store.dispatch(UserActions.CHANGE_CURRENT_PASSWORD, { password: values.confirmpassword })
              .then(() => {
                updatePasswordButton.value?.removeAttribute("data-kt-indicator");
                Swal.fire({
                  text: "Password is successfully changed!",
                  icon: "success",
                  confirmButtonText: "Ok",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-light-primary",
                  },
                }).then(() => {
                  passwordFormDisplay.value = false;
                  actions.resetForm();
                });
              })
          }).catch((error) => {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            // changePasswordSchema.fields.currentpassword.typeError(error.message)
            // console.log(changePasswordSchema)
            currentPasswordError.value = error.message
            console.log(error.message)
          })
      }
    };

    const removeImage = () => {
      profileDetails.value.avatar = "media/avatars/blank.png";
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    return {
      hiddenEmailDisplay,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      removeImage,
      profileDetailsValidator,
      changeEmailSchema,
      changePasswordSchema,
      changeNameSchema,
      updateNameButton,
      updateEmailButton,
      updatePasswordButton,
      updateName,
      updateEmail,
      updatePassword,
      nameFormDisplay,
      currentPasswordError
    };
  },
});
